<template>
  <div class="Enter-box">
    <div class="desktop">
      <div class="Essential-box">
        <ul class="Essential-ul">
          <li @click="$router.push('/DeskManagement')">桌台管理</li>
          <li @click="structure()" class="">归口管理</li>
          <li @click="TOzhuotaiquyu()" class="Essential-li">区域管理</li>
        </ul>
        <div class="shop_choice">
          <span>选择店铺：</span>
          <!-- :class="abc[index] ? 'shopStyleType' : 'shopNoneType'" -->
          <el-select v-model="zt_dp_shop_id" placeholder="请选择" class="Essential-store" @change='shop_boxS'>
            <el-option v-for="(item) in options" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
              <span style="float: left;font-size:14px;">{{item.shop_name}}</span>
              <!-- <span style="float:right;" v-show="item.flag_table === '0'">未设置</span>
              <span class="red_span" style="float:right;" v-show="item.flag_table === '1'">已设置</span> -->
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Essential-structure">
          <el-row :gutter="20" v-for="(item,index) in quyu_list" :key="this">
            <el-col :span="6"><div class="grid-content bg-purple">
              <div class="quyu_name">
               <span> 区域名称： </span> <div><el-input v-model="item.area_name" placeholder="请输入内容"></el-input></div> 
              </div>
              </div></el-col>
            <el-col :span="8" :offset="5"><div class="grid-content bg-purple">
              <div class="quyu_name">
               <span> 选择桌台名：   </span> 
                  <div> 
                    <el-select v-model="item.table_ids"  multiple  placeholder="请选择" @change="handle_xuanzezhuotai">
                      <el-option-group
                        v-for="group in options_zt"
                        :key="group.label"
                        :label="group.label">
                        <el-option
                          :disabled="item.dis"
                          v-for="item in group.options"
                          :key="item.table_id"
                          :label="item.table_name"
                          :value="item.table_id">
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </div> 
              </div>
              </div></el-col>
            <el-col :span="2"><div class="grid-content bg-purple">
              <div class="quyu_name">
               <span class="shan_chu" @click="del_quyu(index)"> 删除 </span>
              </div>
              </div></el-col>
          </el-row>

        <div class="add" @click="add_quyu">
          <c-icon name="xinzeng"> </c-icon>新增区域
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="2" :offset="10"><div class="grid-content_box bg-purple_btn_box">
          <div class="baocun_btn" @click="baocun_btn">保存</div>
          </div></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {
    query_shop_info_list,
    // insert_table_info,
    query_table_info_list,
    // have_exist_table_name,
    // delete_table_info,
    // update_table_info,
    // query_shop_status_list_of_table
  } from '@api/pulic'
 import { 
    query_table_for_area_list, 
    set_table_area
  } from '@api/public'
  export default {
    data() {
      return {
        options:[], // 门店的选择
        zt_dp_shop_id:'',

        quyu_name:'', // 区域名1

        options_zt: [ ],  // 分组 select 选择器

        quyu_list:[ {
          area_name:'',
          table_ids:[],
        } ] // 当前的列表




      }
    },
    created() {
      var that = this;
      query_shop_info_list({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        if (res.code == 200) {
          console.log(res.body.data);
          let dianpu = [];
          res.body.data.forEach(item => {
            dianpu.push(item.shop_id)
          })
          this.options = res.body.data
          this.zt_dp_shop_id = res.body.data[0].shop_id
          this.query_table_info_list()
          this.query_table_for_area_list()
        }
      })

    },
    watch: {

    },
    mounted(){
    },
    methods: {

      structure() {
        this.$router.push('/AttributeManagement')
      },
      TOzhuotaiquyu(){
        // this.$router.push('/zhuotaiquyu')
      },
      shop_boxS(val){
        console.log(val,'|||||',this.zt_dp_shop_id);
          this.query_table_info_list()
          this.query_table_for_area_list()

      },
      handle_xuanzezhuotai(val){
        // console.log(this.quyu_list[0].table_ids,'===||===',val,this.value1);
          this.serarOn_key()
      },
      // 新增区域
      add_quyu(){
        this.quyu_list.push({
          area_name:'',
          table_ids:[],
        })
      },
      del_quyu(index){
        this.quyu_list.splice(index,1)
        this.serarOn_key()
      },
      serarOn_key(val) {
        let arr2 = [];
        this.quyu_list.forEach((item) => {
          arr2 = [...arr2, ...item.table_ids];
        });
        for (const i in this.options_zt) {
          if (this.options_zt[i].options) {
            for (const j in this.options_zt[i].options) {
              if (arr2.includes(this.options_zt[i].options[j].table_id)) {
                this.options_zt[i].options[j].dis = true;
              } else {
                this.options_zt[i].options[j].dis = false;
              }
            }
          }
        }
        console.log('this.options_zt',this.options_zt);
      },
      // 查询设置列表
      query_table_for_area_list(){
        query_table_for_area_list({data:{
              ent_id:this.$store.state.ent_id,
              shop_id:this.zt_dp_shop_id,
        }}).then(res=>{
          console.log('query_table_for_area_list',res)
          if (res.code == 200) {
            let query_for_list = res.body.data
            let for_quyu = []
            for (let i in query_for_list) {
              for_quyu.push({
                area_name:query_for_list[i].area_name,
                table_ids:[]
              })
              for (let a in query_for_list[i].list) {
                if (query_for_list[i].list[a].flag_area == '1') {
                  for_quyu[i].table_ids.push(query_for_list[i].list[a].table_id)
                }
              }
            }
            this.quyu_list = for_quyu
          this.serarOn_key()
          }
        })
      },
        // 获取桌台列表
    query_table_info_list() {
      const param = {
        // ent_id: this.ent_id,
        // shop_id: this.shop_id
        ent_id: this.$store.state.ent_id,
        shop_id: this.zt_dp_shop_id
      }
      query_table_info_list({data: param}).then(res => {
        if(res.code === '200'){
          let data = res.body.data

          console.log('桌台列表',data);
          let len = res.body.data.length
             let obj0 = {
                label:'包厢',
                options: []
              }
              let obj1 = {
                label:'小桌',
                options: []
              }
              let obj2 = {
                label: '中桌',
                options: []
              }
              let obj3 = {
                label: '大桌',
                options: []
              }
          for ( let i=0;i<len;i++){
              if(data[i].table_type === '0'){
                obj0.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
              if(data[i].table_type === '1'){
                     obj1.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
                 if(data[i].table_type === '2'){
                     obj2.options.push({
                  table_id: data[i].table_id,
                  table_name: data[i].table_name,
                  table_type: data[i].table_type
                })
              }
              if(data[i].table_type === '3'){
                     obj3.options.push({
                      table_id: data[i].table_id,
                      table_name: data[i].table_name,
                      table_type: data[i].table_type
                   })
              }
              console.log('[[][][][][]]',[obj0,obj1,obj2,obj3]);
               this.options_zt = [obj0,obj1,obj2,obj3]
          }
          this.serarOn_key()
        }
      })
    },
    // 保存信息
    baocun_btn(){
      let quyu_list = this.quyu_list
      for (let a in quyu_list) {
       quyu_list[a].table_ids = quyu_list[a].table_ids.join(',')
      }
      let list = quyu_list
      // [{
      //   area_name:'',
      //   table_ids:'',
      //   }
      // ]
      let data = {data:{
        ent_id:this.$store.state.ent_id,
        shop_id:this.zt_dp_shop_id,
        list:list,
      }}
      console.log(data);
      set_table_area(data).then(res=>{
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      })

    },

    }
  }
</script>

<style scoped lang="scss">


  .red_span {
    color: #FC4353;
  }

  .shop_choice {
    display: flex;

    &>span {
      color: #666666;
      margin: 50px 10px 0 0;
      font-size: 18px;
    }
  }

  .shopStyleType span,
  .shopNoneType span {
    position: relative;
  }

  .shopNoneType span::before {
    content: '';
    width: 10px;
    right: -15px;
    position: absolute;
    top: 4px;
    height: 10px;
    background: #ccc;
    border-radius: 50%;
    display: block;
  }

  .shopStyleType span::before {
    content: '';
    width: 10px;
    right: -15px;
    position: absolute;
    top: 4px;
    height: 10px;
    background: #FC4353;
    border-radius: 50%;
    display: block;
  }

  .el-main {

    background: #FFFFFF;
  }

  .desktop {
    width: 100%;
    color: #f6f6f3;
    background: url('../../../assets/img/beijing3.png') no-repeat;
    height: 800px;
    background-size: 100% 800px;
    .Essential-box {
      display: flex;
      justify-content: space-between;

      .Essential-ul {
        display: flex;
        padding: 58px 0 0 97px;

        li {
          font-size: 20px;
          color: #666666;
          width: 280px;
          font-weight: 500;
        }

        .Essential-li {
          color: #FE5745;
        }
      }
    }
  }
  .Essential-structure{
    width: 94%;
    height: 500px;
    margin: 50px auto 0 auto;
    overflow-y: auto;
    .el-row {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .el-col {
        border-radius: 4px;
      }
      // .bg-purple-dark {
      //   // background: #99a9bf;
      // }
      .bg-purple {
        // background: #d3dce6;
        .quyu_name{
          color: #333;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          .shan_chu{
            display: block;
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #FE5745;
          }
        }
      }
      .grid-content {
        border-radius: 4px;
        min-height: 240px;
      }
      .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
      }
    > .add {
      display: flex;
      font-size: 26px;
      color:  rgb(245, 62, 62);
      fill: rgb(245, 62, 62);
      height: 110px;
      border: 1px dashed red;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
      // button
    .grid-content_box {
      border-radius: 4px;
      min-height: 40px;
      margin: 20px 0 0 0;
    }
    .bg-purple_btn_box{
      // background: #d3dce6;
      .baocun_btn{
        width: 120px;
        height: 40px;
        background-color: #FC4353;
        color: #FFF ;
        font-size: 20px;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
      }
    }

</style>